import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';

interface Props {
  companyName: string;
  contents: [];
}

const MenuBox: React.FC<Props> = ({companyName, contents}) => {
  return (
    <div className="menu-box">
      <ul>
        {contents.filter(content => !content['content_id'] ).map(content => (
          <>
            <li className={content['icon']}>
              <div className="text-area">
                <p className="title">
                  {content['title']}
                </p>
                <p className="description">
                  {content['description']}
                </p>
              </div>
            </li>
          </>
        ))}
    </ul>
    </div>
  );
}
export default MenuBox
